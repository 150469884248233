import { useState, useEffect, useMemo } from "react";
import AddFormGeneric2 from "../../components/AddFormGeneric/AddFormGeneric2";
import { toast } from "react-toastify";
import useDealOptions from "../../hooks/useDealOptions";
import useUpdateDeal from "../../hooks/useUpdateDeal";

const getInitialValues = (formFields) =>
  formFields.reduce((acc, formField) => {
    const fieldValuePair = {};
    fieldValuePair[formField.name] = formField["defaultValue"];
    return { ...acc, ...fieldValuePair };
  }, {});
const AddFormDeal = (props) => {
  const [dealOptions] = useDealOptions();
  const [updateDeal, data, loading, error] = useUpdateDeal();

  const addFunction = (updateDeal, mutationKeys) => (values) => {
    updateDeal({ variables: mutationKeys(values) });
  };

  const today = useMemo(() => {
    const todayDate = new Date(Date.now());
    const month = (todayDate.getMonth() + 1).toString();
    const monthFull = month.length === 1 ? "0" + month : month;
    const day = todayDate.getDate().toString();
    const dayFull = day.length === 1 ? "0" + day : day;
    return todayDate.getFullYear() + "-" + monthFull + "-" + dayFull;
  }, []);

  // prettier-ignore
  const formFieldsArray = [
    {title: "General", fieldType: "titleSection", rank: 0},
    {name: "olyxRef", label: "Olyx Ref Number", rank: 1, fieldType:"text", yupType: "integer", required: true, defaultValue: "0",},
    {name: "description", label: "Deal Description", rank: 1.1, fieldType:"text", yupType: "string", required: false, defaultValue: "",},
    {name: "dealDate", label: "Deal Date as on Deal Conformation", rank: 2, fieldType:"date", yupType: "date", required: true, defaultValue: today,},
    {name: "newLine", rank: 29.9, fieldType: "newLine"},
    {name: "dateExpectedSeller", label: "Estimated Invoice Date Seller", rank: 33, fieldType:"date", yupType: "date", required: false, defaultValue: today,},
    {name: "dateExpectedBuyer", label: "Estimated Invoice Date Buyer", rank: 34, fieldType:"date", yupType: "date", required: false, defaultValue: today,},
    {title: "Seller&Buyer Info", fieldType: "titleSection", rank: 10},
    {name: "sellerId", label: "Seller", rank: 10, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"clients", optionFieldsToDisplay: ["id", "companyName"]},
    {name: "brokerSellerId", label: "Broker Seller", rank: 11, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"brokers", optionFieldsToDisplay: ["id", "brokerName"]},
    {name: "buyerId", label: "Buyer", rank: 12, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"clients", optionFieldsToDisplay: ["id", "companyName"]},
    {name: "brokerBuyerId", label: "Broker Buyer", rank: 13, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"brokers", optionFieldsToDisplay: ["id", "brokerName"]},
    {title: "Product info", fieldType: "titleSection", rank: 20},
    {name: "productId", label: "Product", rank: 21, fieldType:"select", yupType: "integer", required: false, defaultValue: "0", optionObjects:"products", optionFieldsToDisplay: ["id", "name"], },
    {name: "newLine", rank: 22.01, fieldType: "newLine"},
    {name: "productSpec", label: "Product and Spec Description", rank: 22, fieldType:"text", yupType: "string", required: false, defaultValue: "Product /SpecSet description",},
    {name: "volume", label: "Volume", rank: 23, fieldType:"text", yupType: "integer", required: false, defaultValue: "98",},
    {name: "unitId", label: "Unit", rank: 24, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"units", optionFieldsToDisplay: ["id", "description"]},
    {name: "currencyDealId", label: "Currency Deal", rank: 25, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"currencies", optionFieldsToDisplay: ["id", "description"]},
    {name: "pricePerUnit", label: "Price per Unit", rank: 26, fieldType:"text", yupType: "float", required: false, defaultValue: "98",},
    {title: "Commission info", fieldType: "titleSection", rank: 40},
    {name: "currencyCommissionSellerId", label: "Currency CommissionSeller", rank: 40, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"currencies", optionFieldsToDisplay: ["id", "description"]},
    {name: "commissionSellerUnit", label: "Unit Commission from Seller", rank: 41, fieldType:"text", yupType: "float", required: false, defaultValue: "",},
    {name: "commissionSellerPercentage", label: "Percentage of DealValue Commission from Seller", rank: 41.05, fieldType:"text", yupType: "float", required: false, defaultValue: "",},
    {name: "commissionSeller", label: "Total Commission from Seller", rank: 41.1, fieldType:"textCalculated", yupType: "float", required: false, defaultValue: "98",},
    {name: "newLine", rank: 41.2, fieldType: "newLine"},
    {name: "currencyCommissionBuyerId", label: "Currency CommissionBuyer", rank: 42, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"currencies", optionFieldsToDisplay: ["id", "description"]},
    {name: "commissionBuyerUnit", label: "Unit Commission from Buyer", rank: 43, fieldType:"text", yupType: "float", required: false, defaultValue: "",},
    {name: "commissionBuyerPercentage", label: "Percentage of DealValue Commission from Buyer", rank: 43.05, fieldType:"text", yupType: "float", required: false, defaultValue: "",},
    {name: "commissionBuyer", label: "Total Commission from Buyer", rank: 43.1, fieldType:"textCalculated", yupType: "float", required: false, defaultValue: "98",},
    {name: "newLine", rank: 22.4, fieldType: "newLine"},
    {title: "Other", fieldType: "titleSection", rank: 50},
    {name: "dealStatusId", label: "Deal Status", rank: 51, fieldType:"select", yupType: "integer", required: true, defaultValue: "1", optionObjects:"dealstatuses", optionFieldsToDisplay: ["id", "description"]},
  ];

  const [formFields, setFormFields] = useState(formFieldsArray);
  const [initialValues, setInitialValues] = useState(() => (props.initialValues ? props.initialValues : getInitialValues(formFields)), []);

  useEffect(() => {
    setInitialValues(() => props.initialValues);
  }, [props.initialValues]);

  const mutationKeys = (values) => {
    const variables = {
      olyxRef: values.olyxRef,
      dealId: values.id,
      newInput: values.newInput ?? false,
      dealDate: values.dealDate,
      updatedById: "1",
      productId: values.productId,
      productSpecInput: { description: values.productSpec, productId: values.productId, productSpecPropertyInput: [] },
      termsAgreementId: values.termsAgreementId,
      termsRegionId: values.termsRegionId,
      termsRegion: values.termsRegion,
      currencyDealId: values.currencyDealId,
      pricePerUnit: values.pricePerUnit,
      unitId: values.unitId,
      volume: values.volume,
      currencyCommissionBuyerId: values.currencyCommissionBuyerId,
      currencyCommissionSellerId: values.currencyCommissionSellerId,
      commissionBuyer: values.commissionBuyer,
      commissionSeller: values.commissionSeller,
      buyerId: values.buyerId,
      sellerId: values.sellerId,
      brokerSellerId: values.brokerSellerId,
      brokerBuyerId: values.brokerBuyerId,
      dealStatusId: values.dealStatusId,
      importCountryId: values.importCountryId,
      exportCountryId: values.exportCountryId,
      description: values.description ? values.description : undefined,
      dateExpectedSeller: values.dateExpectedSeller ? values.dateExpectedSeller : undefined,
      dateExpectedBuyer: values.dateExpectedBuyer ? values.dateExpectedBuyer : undefined,
    };
    return { input: { ...variables } };
  };

  const getFormFieldProductSpecPropertyType = (key) => {
    return {
      name: "productSpecPropertyTypeId" + key.toString(),
      label: "Product Spec Property Type " + key.toString(),
      rank: 22.1,
      fieldType: "select",
      yupType: "integer",
      required: false,
      defaultValue: "",
      optionObjects: "productspecpropertytypes",
      optionFieldsToDisplay: ["id", "description"],
    };
  };

  const getFormFieldProductSpecPropertyValue = (key) => {
    return {
      name: "productSpecPropertyValue" + key.toString(),
      label: "Product Spec Property Value " + key.toString(),
      rank: 22.1,
      fieldType: "text",
      yupType: "string",
      required: false,
      defaultValue: "",
    };
  };

  const afterSubmit = (values) => {
    toast.success(`Changes in deal submitted ${values.olyxRef ? "for deal olyxRef " + values.olyxRef : undefined}.`);
    if (props.closeAfterSubmit) {
      props.closeAfterSubmit();
    }
    if (props.refetch) {
      props.refetch();
    }
  };

  return (
    <>
      {false && (
        <button
          onClick={() => {
            setFormFields((formFields) => [
              ...formFields,
              getFormFieldProductSpecPropertyType(formFields.length / 2 - 11),
              getFormFieldProductSpecPropertyValue(formFields.length / 2 - 11),
            ]);
          }}
        >
          Add prodSpecProp Type&Value pair
        </button>
      )}
      {dealOptions && (
        <AddFormGeneric2
          className="addFormGeneric"
          formFields={formFields}
          initialValues={initialValues}
          buttonAddProdSpecProp={true}
          newInput={props.newInput}
          formOptions={dealOptions}
          addFunction={addFunction(updateDeal, mutationKeys)}
          afterSubmit={afterSubmit}
        />
      )}
    </>
  );
};

export default AddFormDeal;
