import "moment-timezone";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpRevExpandable from "../ExpRevExpandable";
import ExpRevSingleProps from "./ExpRevSingleInterfaces";
import helpers from "../../services/helpers";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Moment from "react-moment";
import olyxbase from "../../services/olyxbase";
import PaymentStatusIcon from "../PaymentStatusIcon/PaymentStatusIcon";
import RefreshIcon from "@mui/icons-material/Refresh";

const ExpRevSingle = ({ currentUserIsAdmin, expRev, onClick, enableChangesOnExpRev }: ExpRevSingleProps) => {
  const [showInvoiceList, setShowInvoiceList] = useState<boolean>(false);
  const [invoiceHasBeenMade, setInvoiceHasBeenMade] = useState(false);
  const [invoiceCreationHasFailed, setInvoiceCreationHasFailed] = useState(false);
  const [invoiceCreationIsProcessing, setInvoiceCreationIsProcessing] = useState(false);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { opacity: { delay: 0.2 } } },
    exit: { opacity: 0, transition: { height: { delay: 0.2 } } },
  };

  async function handleSendInvoice(expectedRevenueId: string) {
    // Invoice single expected revenue
    setInvoiceCreationIsProcessing(true);
    const createdInvoiceSuccessfully = await olyxbase.createInvoice(parseInt(expectedRevenueId, 10));
    setInvoiceCreationIsProcessing(false);
    setInvoiceHasBeenMade(createdInvoiceSuccessfully);
    setInvoiceCreationHasFailed(!createdInvoiceSuccessfully);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className={`relative grid w-full  
       col-span-6
      ${currentUserIsAdmin ? "grid-cols-7" : "grid-cols-6"}
      ${expRev.note ? "mb-[4px]" : "mb-[0px]"} border-gray-200 border rounded-xl py-1`}
    >
      <div className="col-span-1  flex items-start justify-center">
        <PaymentStatusIcon size={"medium"} invoiceStatusSummary={expRev.invoiceStatusSummary}></PaymentStatusIcon>
      </div>
      <div className="text-[#7E8085] col-span-1 flex items-center text-xs">
        <p> {helpers.formatMoney(helpers.getExpRevSum(expRev), expRev.currency.description)}</p>
      </div>
      <div className="text-[#7E8085] col-span-1 flex items-center text-xs">
        {expRev.volume ? helpers.changeDatabaseValueToDisplayValue(expRev.volume).toLocaleString("en-US") : "-"}
      </div>
      <div className="text-[#7E8085] col-span-2 flex items-center text-xs">
        {expRev.dateExpected ? <Moment format="D MMM YYYY">{new Date(expRev.dateExpected)}</Moment> : "-"}
      </div>
      {currentUserIsAdmin && (
        <>
          <div className="col-span-2 flex">
            <div className=" ml-1 flex items-center">
              <p className="text-[#7E8085] text-xs">{expRev.id}</p>
            </div>
            <div className="w-[16px] flex items-center">
              <div className="text-[#7E8085]">
                <motion.div
                  className="cursor-pointer w-[16px]"
                  onClick={() => setShowInvoiceList(!showInvoiceList)}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={variants}
                >
                  <KeyboardArrowDownIcon viewBox="0 0 32 26" className={`${showInvoiceList ? "transform rotate-180" : "transform rotate-0"}`} />
                </motion.div>
              </div>
            </div>
            {enableChangesOnExpRev && (
              <motion.button
                onClick={onClick}
                initial={{ scale: 1 }}
                whileHover={{ scale: 1, rotate: 15 }}
                whileTap={{ scale: 1 }}
                className="absolute right-[10px] top-[4px] transform w-[16px]"
              >
                <EditIcon className="pb-[5px] pt-[3px]" style={{ color: "grey" }} />
              </motion.button>
            )}
          </div>
          <div className="col-span-1 flex justify-center items-center">
            {(expRev.invoiceStatusSummary === "ABOUTTOSEND" || expRev.invoiceStatusSummary === "UNKNOWN") && (
              <button
                disabled={invoiceHasBeenMade}
                className={`${invoiceCreationHasFailed ? "text-red-500 bg-transparent" : ""} ${
                  invoiceHasBeenMade ? "text-[#c9c8c8] bg-transparent" : "bg-primary-blue"
                } ${invoiceCreationIsProcessing ? "text-primary-blue bg-transparent" : ""}
                  flex gap-[2px] items-center rounded h-24px px-[4px] text-white text-xs absolute top-[8px] right-[24px]`}
                onClick={() => {
                  handleSendInvoice(expRev.id);
                }}
              >
                {invoiceCreationIsProcessing && <RefreshIcon fontSize="small" className=" animate-spin" />}
                {invoiceHasBeenMade && <CheckIcon fontSize="small" />}
                {invoiceCreationHasFailed && <ErrorOutlineIcon fontSize="small" />}
                {(() => {
                  let message;
                  if (invoiceCreationHasFailed) {
                    message = "Failed";
                  } else if (invoiceHasBeenMade) {
                    message = "Done";
                  } else if (invoiceCreationIsProcessing) {
                    message = "";
                  } else {
                    message = "Invoice";
                  }
                  return message;
                })()}
              </button>
            )}
          </div>
        </>
      )}
      <AnimatePresence>
        {showInvoiceList && currentUserIsAdmin && (
          <ExpRevExpandable currentUserIsAdmin={currentUserIsAdmin} expRev={expRev} enableChangesOnExpRev={enableChangesOnExpRev}></ExpRevExpandable>
        )}
      </AnimatePresence>
      {expRev.note && (
        <div className="absolute flex w-fit items-center justify-center bottom-[-16px] right-[10px] h-[16px] rounded-br-[8px] rounded-bl-[8px] px-[8px] pt-[2px] bg-[#aeaeae]">
          <p className="leading-none h-[12px] bg-transparent text-[12px] w-full text-white ">{expRev.note}</p>
        </div>
      )}
    </motion.div>
  );
};

export default ExpRevSingle;
