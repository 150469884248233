import CheckboxesProps from "../../../interfaces/CheckboxesProps";

const Checkboxes = (props: CheckboxesProps) => {
  return (
    <div
      className={`p-1 flex flex-nowrap rounded-xl border border-solid border-gray-darker text-base items-center h-[2.7rem] max-md:text-xs md:text-base ${
        props.order ? props.order : ""
      }`}
    >
      <span className=" px-1 opacity-50 text-dark-gray-filters max-md:text-xs md:text-base">{props.label}:</span>
      {props.checkboxOptions?.map((option) => (
        <label key={option.value} className="ml-4 mr-2 checkbox block relative cursor-pointer select-none max-md:text-xs md:text-base">
          <input
            className="mr-1 absolute opacity-0 left-0 top-0 cursor-pointer"
            type="checkbox"
            value={option.value}
            checked={option.checked}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          ></input>
          <span className="h-3 w-3 CheckboxCheckmark absolute max-md:top-0.5 md:top-1.5 -left-4 rounded-sm border border-solid border-gray-darker"></span>
          <span className="text-gray-darker text-[14px]">{option.label}</span>
        </label>
      ))}
    </div>
  );
};

export default Checkboxes;
