import React from "react";
import "./BidOfferEntry.css";
import hero from "../../assets/trees.jpg";
import BidOfferEntryTable from "../BidOfferEntryTable";

export default function BidOfferEntry({ stacks, updateBo, lastUpdate, dhl, setDisableHoverListen }) {
  return stacks && stacks.group1 && stacks.group1.category ? (
    <>
      <div className="boe-outer">
        <img className="boe-hero" src={hero} />
        <div className="boe-update-side">Last Updated: {lastUpdate}</div>

        <div className="table-parent">
          <div>
            <BidOfferEntryTable
              category={stacks.group1.category}
              products={stacks.group1.products}
              updateBo={updateBo}
              dhl={dhl}
              setDisableHoverListen={setDisableHoverListen}
            />
            <div className={"h-5"}></div>
            <BidOfferEntryTable
              category={stacks.group6.category}
              products={stacks.group6.products}
              updateBo={updateBo}
              dhl={dhl}
              setDisableHoverListen={setDisableHoverListen}
            />
          </div>
          <div>
            <BidOfferEntryTable
              category={stacks.group2.category}
              products={stacks.group2.products}
              updateBo={updateBo}
              dhl={dhl}
              setDisableHoverListen={setDisableHoverListen}
            />
            <div className={"h-5"}></div>
            <BidOfferEntryTable
              category={stacks.group7.category}
              products={stacks.group7.products}
              updateBo={updateBo}
              dhl={dhl}
              setDisableHoverListen={setDisableHoverListen}
            />
          </div>
          <div>
            <BidOfferEntryTable
              category={stacks.group3.category}
              products={stacks.group3.products}
              updateBo={updateBo}
              dhl={dhl}
              setDisableHoverListen={setDisableHoverListen}
            />
          </div>
          <div>
            <BidOfferEntryTable
              category={stacks.group4.category}
              products={stacks.group4.products}
              updateBo={updateBo}
              dhl={dhl}
              setDisableHoverListen={setDisableHoverListen}
            />
            <div className={"h-5"}></div>
            <BidOfferEntryTable
              category={stacks.group5.category}
              products={stacks.group5.products}
              updateBo={updateBo}
              dhl={dhl}
              setDisableHoverListen={setDisableHoverListen}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <h3>Loading..</h3>
  );
}
