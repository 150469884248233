import { Close as CloseIcon } from "@material-ui/icons";
import { format, isValid } from "date-fns";
import { motion } from "framer-motion";
import { useState } from "react";
import FormLongTermDealsProps from "../../interfaces/FormLongTermDealsProps";
import helpers from "../../services/helpers";
import useUpdateExpectedRevenue from "../../typedHooks/mutations/useUpdateExpectedRevenue";
import PaymentStatusIcon from "../PaymentStatusIcon/PaymentStatusIcon";

const FormLongTermDeals = (props: FormLongTermDealsProps) => {
  const defaultCurrencyId = 2;
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [dateExpected, setDateExpected] = useState<string>(props.dateExpected ? props.dateExpected : "");
  const [note, setNote] = useState<string>(props.note ? props.note : "");
  const [currencyId, setCurrencyId] = useState<number>(props.currencyId ? props.currencyId : defaultCurrencyId);
  const [totalAmount, setTotalAmount] = useState<string>(props.totalAmount || props.totalAmount === 0 ? String(props.totalAmount) : "");
  const [volume, setVolume] = useState(props.volume || props.volume === 0 ? String(props.volume) : "");
  const [newExpectedRevenue, setNewExpectedRevenue] = useState<number>(0);
  const currencies = helpers.currencies;

  // sets the values to default when closing or submitting expRevenue
  const refreshToDefault = () => {
    setDateExpected("");
    setCurrencyId(defaultCurrencyId);
    setTotalAmount("");
    setVolume("");
    setNote("");
  };

  const updateExpectedRevenue = useUpdateExpectedRevenue();

  const handleSubmit = async (totalAmount: string, clientId: string, currencyId: number, date: string, dealId: string, tradeSide: string, note: string) => {
    try {
      // validation
      const fdate = Date.parse(date);
      if (!isValid(fdate)) return alert("Date must be in dd mmm yyyy format");
      if (totalAmount === "" || isNaN(Number(totalAmount))) return alert("Amount must be a number");
      // increasing the counter to show we created one expected revenue if everything went well
      const localDate = new Date(fdate);
      const dateExpectedToUTC = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
      await updateExpectedRevenue({
        variables: {
          input: {
            id: props.expRevId,
            currencyId: currencyId,
            totalAmount: helpers.changeValueToDatabaseValue(totalAmount),
            dateExpected: dateExpectedToUTC,
            tradeSide: tradeSide,
            dealId: parseInt(dealId),
            clientId: parseInt(clientId),
            note: note,
            volume: helpers.changeValueToDatabaseValue(volume),
          },
        },
      }).then((value) =>
        value.data?.createexpectedrevenue?.__typename === "ExpectedRevenue"
          ? setNewExpectedRevenue(newExpectedRevenue + 1)
          : alert("Something went wrong, try again")
      );
    } catch (err) {
      console.log(err);
      alert("Something went wrong, try again");
    }
  };

  const handleVolumeChange = (value: string) => {
    const regex = /^\d*\.?\d{0,2}$/;

    if (regex.test(value)) {
      setVolume(value);
    }
  };

  return (
    <>
      {(isFormOpen || props.updatingExpRevenue) && props.currentUserIsAdmin && (
        <div className={"relative mb-[4px] min-h-[34px] grid grid-cols-7  w-full border-gray-200 border rounded-xl py-1 items-center"}>
          <div className="col-span-1 flex text-[#7E8085] flex-wrap justify-center h-[24px]">
            <PaymentStatusIcon size={"medium"} additionalStyle="border-gray-200 border" invoiceStatusSummary="FUTURE"></PaymentStatusIcon>
          </div>
          <div className="text-[#7E8085] col-span-1 flex justify-center h-[24px] bg-slate-200 rounded-tl-[5px] rounded-bl-[5px]">
            <select
              defaultValue={currencyId}
              className=" w-1/6 min-w-fit text-center text-xs center focus:outline-none appearance-none hover:text-[#4c4c4e] bg-transparent"
              onChange={(e) => {
                setCurrencyId(parseInt(e.target.value) ? parseInt(e.target.value) : defaultCurrencyId);
              }}
            >
              {currencies.map((currency) => {
                return (
                  <option value={currency.id} key={currency.id} onClick={() => setCurrencyId(currency.id)}>
                    {currency.symbol}
                  </option>
                );
              })}
            </select>
            <input
              type="text"
              placeholder="Amount"
              className="text-xs focus:outline-none w-5/6 bg-transparent"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
            />
          </div>
          <div className="col-span-1 m-auto h-[24px] flex items-center bg-slate-200 border-l-[1px] border-white">
            <textarea
              className="text-[#7E8085] h-full pt-1 pl-[2px] expRev-volume text-xs w-full bg-transparent focus:outline-none"
              value={volume}
              onChange={(e) => {
                const valueReadyToSubMit = helpers.cleanUpUserNumberInput(e.target.value);
                if (valueReadyToSubMit !== false) {
                  handleVolumeChange(valueReadyToSubMit);
                }
              }}
            />
          </div>
          <div className="text-[#7E8085] col-span-2 h-[24px] flex items-center bg-slate-200 rounded-tr-[5px] rounded-br-[5px] border-l-[1px] border-white">
            <input
              type="text"
              className="pl-[2px] w-24 text-xs text-left focus:outline-none bg-transparent"
              value={dateExpected}
              placeholder={`${format(new Date(Date.now()), "dd MMM yyyy")}`}
              onChange={(e) => setDateExpected(e.target.value)}
            />
          </div>
          <div className="col-span-2 py-[2px] flex justify-end flex-wrap items-center">
            <button
              type="submit"
              className="px-[4px] rounded text-xs w-fit h-[16px] bg-primary-blue text-white"
              onClick={() => {
                handleSubmit(totalAmount, props.clientId, currencyId, dateExpected, props.dealId, props.tradeSide, note);
                setIsFormOpen(false);
                refreshToDefault();
                props.setExpectedRevenueIsChanging && props.setExpectedRevenueIsChanging(0);
              }}
            >
              {props.updatingExpRevenue ? "Update" : "Create"}
            </button>
            <motion.button
              onClick={() => {
                setIsFormOpen(false);
                refreshToDefault();
                props.setExpectedRevenueIsChanging && props.setExpectedRevenueIsChanging(0);
              }}
              initial={{ scale: 1 }}
              whileHover={{ scale: 1, rotate: 90 }}
              whileTap={{ scale: 1 }}
              className="transform mr-[4px]"
            >
              <CloseIcon className="hover:text-[#4c4c4e]" fontSize="small"></CloseIcon>
            </motion.button>
          </div>
          <div className="absolute flex w-[240px] items-center justify-center bottom-[-16px] right-[10px] h-[16px] rounded-br-[8px] rounded-bl-[8px] px-[8px] pt-[2px] bg-[#aeaeae]">
            <input
              type="text"
              className="leading-none h-[12px] bg-transparent text-[12px] w-full text-white placeholder-[#e6e6e6] focus:outline-none font-smallText"
              value={note}
              placeholder={"Add a note"}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
      )}
      {!(isFormOpen || props.updatingExpRevenue) && props.currentUserIsAdmin && (
        <div>
          <button className="text-xs" onClick={() => setIsFormOpen(true)}>
            + add expRev
          </button>
          {newExpectedRevenue !== 0 && <p> You added {newExpectedRevenue} expected revenues</p>}
        </div>
      )}
    </>
  );
};

export default FormLongTermDeals;
