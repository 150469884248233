import { gql } from "@apollo/client";

const GET_DEAL = gql`
  query Deal($id: String!) {
    deals(id: $id) {
      id
      olyxRef
      seller {
        id
        companyName
      }
      buyer {
        id
        companyName
      }
      brokerSeller {
        id
        brokerName
        userInfo {
          id
          imageUrl
        }
      }
      brokerBuyer {
        id
        brokerName
        userInfo {
          id
          imageUrl
        }
      }
      volume
      unit {
        id
        description
        descriptionShort
      }
      currencyDeal {
        id
        description
      }
      pricePerUnit
      currencyCommissionSeller {
        id
        description
      }
      currencyCommissionBuyer {
        id
        description
      }
      commissionBuyer
      commissionSeller
      dealStatus {
        id
        description
      }
      dealDate
      description
      termsRegion {
        id
        description
      }
      termsAgreement {
        id
        description
      }
      exportCountry {
        id
        name
      }
      importCountry {
        id
        name
      }
      productSpec {
        id
        description
        product {
          id
          name
        }
      }
      product {
        id
        name
      }
      expectedRevenue {
        id
        client {
          id
          companyName
        }
        dateExpected
      }
      expectedRevenueAgainstInvoices {
        tradeSide
        judgement
        allInvoicesAreClosed
        clientOnDeal {
          id
          companyName
        }
        expectedRevenue {
          id
          note
          totalAmount
          totalAmountPlusChanges
          invoiceStatusSummary
          volume
          currency {
            id
            description
          }
          client {
            id
            companyName
          }
          dateExpected
          allocatedFromAllInvoices
          invoices {
            invoiceNumber
            orderNumber
            currency {
              id
              description
            }
            totalAmount
            allocatedToParentExpectedRevenue
            invoiceStatus {
              id
              name
            }
            dateOrder
            isDeleted
            invoiceStatusSummary
          }
          expectedRevenueChanges {
            id
            amount
            reason
          }
        }
      }
      dealNotes {
        id
        createdAt
        createdBy {
          userUid
          fullName
          imageUrl
        }
        client {
          id
          companyName
        }
        note
        inProgressAt
        inProgressBy {
          id
          fullName
          imageUrl
        }
        doneAt
        doneBy {
          id
          fullName
          imageUrl
        }
        status
      }
      dealConfirmationBuy {
        createdAt
        id
        isConfirmed
        tradeSide
        updatedAt
        updatedBy {
          id
          fullName
          imageUrl
        }
      }
      dealConfirmationSell {
        createdAt
        id
        isConfirmed
        tradeSide
        updatedAt
        updatedBy {
          id
          fullName
          imageUrl
        }
      }
    }
  }
`;

export default GET_DEAL;
