import { AnimatePresence, motion, Variants } from "framer-motion";
import { DealNote } from "../../codegen/gql/graphql";
import SingleDealNote from "../../components/SingleDealNote/SingleDealNote";
import EmptyState from "../EmptyState/EmptyState";

const CommentsColumn = ({ dealNotes, emptyState }: { dealNotes: DealNote[]; emptyState: string }) => {
  const container: Variants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  const item: Variants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <div className="w-full ml-auto comment-block comment-block-65vh ">
        <AnimatePresence>{dealNotes && dealNotes.length < 1 && <EmptyState title={emptyState} />}</AnimatePresence>
        <motion.div
          className="timeline-container-scroll"
          variants={container}
          initial="hidden"
          animate={dealNotes && dealNotes.length > 0 ? "visible" : "hidden"}
          exit="hidden"
        >
          <AnimatePresence>
            {dealNotes &&
              dealNotes
                .filter((dn: DealNote) => dn.deal)
                .map((dealNote: DealNote, index: number) => {
                  return (
                    <motion.div variants={item} key={dealNote.id + dealNote.deal.olyxRef}>
                      <SingleDealNote dealNote={dealNote} olyxRef={dealNote.deal.olyxRef} dealId={dealNote.deal.id} />
                    </motion.div>
                  );
                })}
          </AnimatePresence>
        </motion.div>
      </div>
    </>
  );
};
export default CommentsColumn;
