import { useState } from "react";
import { DealNote } from "../../codegen/gql/graphql";
import { useActivityHubFilter } from "../../contexts/ActivityHubContexts";
import CheckboxFilter from "../../interfaces/CheckboxFilter";
import { FormField } from "../../interfaces/FormGeneratorProps";
import CommentsCount from "../CommentsCount/CommentsCount";
import FormGenerator from "../FormGenerator/FormGenerator";
import CommentsColumn from "./CommentsColumn";

const ActivityHubComments = ({
  dealNotesToDo,
  dealNotesInProgress,
  dealNotesDone,
}: {
  dealNotesToDo: DealNote[] | undefined;
  dealNotesInProgress: DealNote[] | undefined;
  dealNotesDone: DealNote[] | undefined;
}) => {
  const { mentioned, others, sent, commentsView, setCommentsView, setMentioned, setOthers, setSent } = useActivityHubFilter();

  const checkBoxOptions: CheckboxFilter[] = [
    { label: "mentioned", value: "mentioned", checked: mentioned },
    { label: "sent", value: "sent", checked: sent },
    { label: "others", value: "others", checked: others },
  ];

  const filterCheckboxes: { [key: string]: FormField } = {
    TodoFilter: {
      type: "checkboxes",
      checkboxInitialValue: checkBoxOptions,
      label: "To do",
      order: "order-2",
      onChange: (value: string) => {
        if (value === "mentioned") {
          setMentioned(!mentioned);
        } else if (value === "sent") {
          setSent(!sent);
        } else if (value === "others") {
          setOthers(!others);
        }
      },
      // to match options interface
      checkboxOptions: checkBoxOptions,
    },
  };

  return (
    <div className="rounded-2xl bg-white flex flex-wrap w-full xl:w-[calc(66%-16px)] relative">
      <aside className="-rotate-90 absolute top-[148px] -left-[28px]">
        <h1 className="bg-[#86CEF3] w-[132px] h-[28px] absolute top-0 whitespace-nowrap rounded-tl-[6px] rounded-tr-[6px] px-[4px] py-[8px] pt-[10px] text-[14px] text-center leading-none text-white">
          Comments
        </h1>
      </aside>
      <div className="p-4 w-full xl:w-1/2 xl:pr-0 h-fit">
        <div className="flex flex-wrap sm:flex-nowrap gap-4 items-end">
          <div className="ml-4 flex gap-2 w-full sm:w-fit order-last sm:order-first">
            <h2 className="text-xl">Open</h2>
            <CommentsCount title={"To do"} count={dealNotesToDo ? dealNotesToDo.length : 0}></CommentsCount>
          </div>
          <FormGenerator
            onSubmitFromParent={(e) => {
              e.preventDefault();
            }}
            layoutOptions={{
              hasLabelsOnTop: true,
              hasCloseButton: false,
            }}
            customFormStyles={{
              parent: "h-full  w-full rounded-xl border-box flex flex-wrap gap-x-[1%] bg-white justify-start",
              children: "w-full py-[10px] pt-[24px] md:p-[0px] md:w-[33%] md:pt-[0px] xl:w-[25%]",
            }}
            formFields={[...Object.values(filterCheckboxes)]}
          ></FormGenerator>
        </div>
        <CommentsColumn emptyState="No comments in to do" dealNotes={dealNotesToDo ? (dealNotesToDo as DealNote[]) : []}></CommentsColumn>
      </div>
      <div className="relative w-full xl:w-1/2">
        <div className="p-4 xl:pl-0 w-full h-fit relative flex flex-wrap gap-x-[10px]">
          <div className="flex items-end flex-wrap min-h-[44px]">
            <div
              onClick={() => setCommentsView("InProgress")}
              className={`border-b-2 flex  items-center gap-2 w-fit  ml-4 px-1 ${
                commentsView === "InProgress" ? "border-primary-blue" : " border-white cursor-pointer"
              }`}
            >
              <h2 className="text-xl">In progress</h2>
              <CommentsCount title={"In progress"} count={dealNotesInProgress ? dealNotesInProgress.length : 0}></CommentsCount>
            </div>
            <div
              onClick={() => setCommentsView("Done")}
              className={`border-b-2 flex items-center gap-2 w-fit ml-4 px-1 ${
                commentsView === "Done" ? "border-primary-blue" : " border-white cursor-pointer"
              }`}
            >
              <h2 className="text-xl">Done</h2>
              <CommentsCount title={"Done"} count={dealNotesDone ? dealNotesDone.length : 0}></CommentsCount>
            </div>
          </div>

          {commentsView === "InProgress" && (
            <CommentsColumn emptyState="No comments in progress" dealNotes={dealNotesInProgress ? (dealNotesInProgress as DealNote[]) : []}></CommentsColumn>
          )}
          {commentsView === "Done" && (
            <CommentsColumn emptyState="No comments done" dealNotes={dealNotesDone ? (dealNotesDone as DealNote[]) : []}></CommentsColumn>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityHubComments;
