import { DocumentNode, OperationVariables, TypedDocumentNode } from "@apollo/client";
import { useState } from "react";
import useGeneratedTypeQuery from "./useGeneratedTypeQuery";

function useGeneratedTypeQueryWithPagination<T, I = {}>(query: DocumentNode | TypedDocumentNode<T, OperationVariables>, input: I) {
  const [searchParams, setSearchParams] = useState<I>(input);
  const [previousCursors, setPreviousCursors] = useState<number[]>([]);

  const { dataFromQuery, refetch, fetchMore } = useGeneratedTypeQuery<T, I>(query, input);

  const navigateForward = (pageCursorEnd: number) => {
    fetchMore({ variables: { input: { ...searchParams, cursor: pageCursorEnd } } });

    if (pageCursorEnd !== 0) {
      setPreviousCursors(() => [...previousCursors, pageCursorEnd]);
    }
  };

  const navigateBack = () => {
    const previousPreviousCursorTemp = previousCursors.length < 2 ? undefined : previousCursors.slice(-2)[0];

    fetchMore({ variables: { input: { ...searchParams, cursor: previousPreviousCursorTemp } } });

    setPreviousCursors(() => [...previousCursors.slice(0, -1)]);
  };

  const searchForMoreData = (values: I) => {
    setSearchParams(values);
    setPreviousCursors([]);
    fetchMore({ variables: { input: { ...input, ...values } } });
  };

  return { dataFromQuery, refetch, fetchMore, navigateForward, navigateBack, searchForMoreData };
}

export default useGeneratedTypeQueryWithPagination;
