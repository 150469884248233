import { ReactComponent as OlyxCollaboration } from "../assets/customicons/OlyxCollaboration.svg";
import { ReactComponent as OlyxBiofuel } from "../assets/customicons/OlyxBiofuels.svg";
import { ReactComponent as OlyxEnvironmentalTicket } from "../assets/customicons/OlyxEnvironmentalticket.svg";
import { ReactComponent as OlyxCropfeedstocks } from "../assets/customicons/OlyxCropfeedstocks.svg";
import { ReactComponent as OlyxBiogas } from "../assets/customicons/OlyxBiogas.svg";
import { ReactComponent as OlyxMarketintelligence } from "../assets/customicons/OlyxMarketintelligence.svg";
import { ReactComponent as OlyxOther } from "../assets/customicons/OlyxOther.svg";

const helpers = {};

helpers.getTotalAmountOfChanges = (expRev) => {
  const expRevChanges = expRev.expectedRevenueChanges.filter((expRevChange) => true);
  const totalAmountOfChanges = expRevChanges.reduce((acc, expRevChange) => acc + expRevChange.amount, 0);
  return (totalAmountOfChanges / 100).toString();
};

helpers.sortNameAlphabetic = (first, second) => {
  if (first && first.name && second && second.name) {
    return first.name.toLowerCase() > second.name.toLowerCase();
  }
  return true;
};

// given the selectedSort = {key, direction} sorts in selectedSort.direction based on the key column of the data
helpers.sortNumber = (selectedSort, data) => {
  // // Check if something is being sorted and sort the array in the right direction
  const sortedData = [...data].sort((a, b) => {
    if (selectedSort.direction === "ascending") {
      return a[selectedSort.key] - b[selectedSort.key];
    }
    if (selectedSort.direction === "descending") {
      return b[selectedSort.key] - a[selectedSort.key];
    }
  });
  return sortedData;
};

// given the selectedSort = {key, direction} sorts in selectedSort.direction based on the key column of the data
helpers.sortOnDate = (selectedDate, data) => {
  const sortedData = [...data].sort((a, b) => {
    if (selectedDate.direction === "ascending") {
      return new Date(a[selectedDate.key]) - new Date(b[selectedDate.key]);
    }
    if (selectedDate.direction === "descending") {
      return new Date(b[selectedDate.key]) - new Date(a[selectedDate.key]);
    }
  });
  return sortedData;
};

// creates the updated commission to display in the card summing all the totalUpdatedCommissions in the expectedRevenue array
helpers.getNewCommission = (expectedRevenue) => {
  const initialValue = 0;
  const newCommission =
    expectedRevenue && Array.isArray(expectedRevenue)
      ? expectedRevenue.reduce((commissionSum, expRev) => commissionSum + expRev.totalAmountPlusChanges, initialValue)
      : 0;
  return newCommission ? newCommission / 100 : 0;
};

// gets the currency in the expectedRevenueArray, if there are multiples, return undefined
helpers.getCommissionCurrency = (expectedRevenue) => {
  const finalCurrency =
    expectedRevenue && Array.isArray(expectedRevenue) && expectedRevenue.length > 0 && expectedRevenue[0].currency && expectedRevenue[0].currency.description
      ? expectedRevenue[0].currency
      : undefined;
  const check =
    expectedRevenue &&
    finalCurrency &&
    Array.isArray(expectedRevenue) &&
    expectedRevenue.length > 0 &&
    expectedRevenue.every((e) => e.currency.description === finalCurrency.description);
  return check ? finalCurrency : undefined;
};

helpers.getExpRevSum = (expRev) => {
  const expRevChanges = expRev.expectedRevenueChanges.reduce((acc, expRevChange) => acc + expRevChange.amount, 0);
  return (expRev.totalAmount + expRevChanges) / 100;
};

helpers.formatMoney = (number, currency) => {
  if (number === null || number === "") {
    return "";
  }
  // if function is called with argument { number: number, currency: currency }, recall function
  if (Object.keys(number).length > 0 && Object.keys(number).includes("number") && Object.keys(number).includes("currency")) {
    return helpers.formatMoney(number.number, number.currency);
  }
  if ((Number(number) * 100000) % 10 !== 0) {
    return Number(number).toLocaleString("en-US", currency ? { style: "currency", currency: currency, maximumFractionDigits: 6 } : undefined);
  }
  if ((Number(number) * 1000) % 10 !== 0) {
    return Number(number).toLocaleString("en-US", currency ? { style: "currency", currency: currency, maximumFractionDigits: 4 } : undefined);
  }
  if ((Number(number) * 10) % 10 !== 0) {
    return Number(number).toLocaleString("en-US", currency ? { style: "currency", currency: currency, maximumFractionDigits: 2 } : undefined);
  }
  return Number(number).toLocaleString("en-US", currency ? { style: "currency", currency: currency, maximumFractionDigits: 0 } : undefined);
};

helpers.formatMoneyKMB = (number, currency) => {
  if (number === null || number === "" || typeof number === "undefined") {
    return "";
  }
  if (Number(number) < 0) {
    return `-${helpers.formatMoneyKMB(-1 * Number(number), currency)}`;
  }
  if (Number(number) >= Math.pow(10, 9)) {
    return helpers.formatMoney(Math.round(Number(number) / Math.pow(10, 7)) / Math.pow(10, 2), currency) + "B";
  } else if (Number(number) >= Math.pow(10, 6)) {
    return helpers.formatMoney(Math.round(Number(number) / Math.pow(10, 4)) / Math.pow(10, 2), currency) + "M";
  }
  if (Number(number) >= Math.pow(10, 3)) {
    return helpers.formatMoney(Math.round(Number(number) / Math.pow(10, 3)) / Math.pow(10, 0), currency) + "K";
  } else {
    return helpers.formatMoney(Math.round(Number(number)), currency);
  }
};

helpers.centsToFormattedAmount = (cents, currency) => {
  if (cents === null || cents === "" || typeof cents === "undefined") {
    return "";
  } else {
    return helpers.formatMoney(cents / 100, currency);
  }
};

helpers.moneyEmoji = (currency) => {
  return "🪙";
  if (currency === "EUR") {
    return "💶";
  } else if (currency === "GBP") {
    return "💷";
  } else if (currency === "USD") {
    return "💵";
  } else if (currency === "JPY" || currency === "CNY") {
    return "💴";
  } else {
    return "🪙";
  }
};

helpers.currencies = [
  { id: 1, description: "USD", symbol: "$" },
  { id: 2, description: "EUR", symbol: "€" },
  { id: 3, description: "GBP", symbol: "£" },
  { id: 4, description: "JPY", symbol: "¥" },
  { id: 5, description: "CNY", symbol: "CN¥" },
  { id: 6, description: "CAD", symbol: "CA$" },
  { id: 7, description: "MYR", symbol: "RM" },
  { id: 8, description: "AED", symbol: "AED" },
];

helpers.shortenCompanyName = (companyName) => {
  const maxLength = 10;
  const words = companyName.split(/\s+/);
  if (words.length >= 1) {
    if (words.length === 1 || words[0].length >= maxLength) {
      return words[0];
    } else if (words.length === 2 || (words.length >= 2 && words[0].length + words[1].length >= maxLength)) {
      return words[0] + " " + words[1];
    } else if (words.length === 3 || (words.length >= 3 && words[0].length + words[1].length + words[2].length > maxLength)) {
      return words[0] + " " + words[1] + " " + words[2];
    } else if (words.length === 4 || (words.length >= 4 && words[0].length + words[1].length + words[2].length + words[3].length > maxLength)) {
      return words[0] + " " + words[1] + " " + words[2];
    }
    return companyName.slice(0, maxLength);
  }
};

// function getInitialValues copied from SingleDeal.js. Now shares with AddDealSelect.js as well.
helpers.getInitialValuesDeal = (data) => {
  const initalValues = Object.keys(data).reduce((acc, key) => {
    let obj1 = {};
    if (data[key] !== undefined && data[key] !== null && key !== "__typename") {
      if (Array.isArray(data[key])) {
        if (key === "expectedRevenue") {
          obj1 = data[key].reduce((expRevAcc, expRev) => {
            let expRevExpectedDates = {};
            if (expRev.client && expRev.client.id && data.seller && data.seller.id && expRev.client.id === data.seller.id) {
              expRevExpectedDates["dateExpectedSeller"] = expRev.dateExpected ?? undefined;
            }
            if (expRev.client && expRev.client.id && data.buyer && data.buyer.id && expRev.client.id === data.buyer.id) {
              expRevExpectedDates["dateExpectedBuyer"] = expRev.dateExpected ?? undefined;
            }
            return { ...expRevAcc, ...expRevExpectedDates };
          }, {});
        }
      } else if (typeof data[key] === "object") {
        if (key === "productSpec" && data["productSpec"]["description"] !== null) {
          obj1["productSpec"] = data["productSpec"]["description"];
        } else {
          obj1[key + "Id"] = data[key]["id"].toString();
        }
      } else {
        obj1[key] = data[key].toString();
      }
    } else if (data[key] === null) {
      obj1[key] = "";
    }
    return { ...acc, ...obj1 };
  }, {});
  return initalValues;
};

helpers.currencyIdToSymbol = (currencyId) => {
  if (currencyId.toString() === "1") return "$";
  if (currencyId.toString() === "2") return "€";
  if (currencyId.toString() === "3") return "£";
  return "";
};

helpers.getCommissionPercentage = (deal, side, actualCommission) => {
  if (side !== "seller" && side !== "buyer") {
    return "";
  }

  if (deal && deal.volume && deal.pricePerUnit && deal.pricePerUnit > 0 && actualCommission) {
    return Number(parseInt((Number(actualCommission) / (Number(deal.volume) * Number(deal.pricePerUnit))) * 100 * 1000) / 1000).toString();
  }
  return "";
};

helpers.roundNice = (num, decimals) => {
  return +(Math.round(num.toString() + "e+" + decimals.toString()) + "e-" + decimals.toString());
};

helpers.roundPriceInt = (priceInt, Storagedecimals, roundingDecimals) => {
  // prettier-ignore
  let price = priceInt / (10 ** Storagedecimals);
  return +(Math.round(price.toString() + "e+" + roundingDecimals.toString()) + "e-" + roundingDecimals.toString());
};

helpers.roundPriceWithTick = (priceInt, storageDecimals, tickSize) => {
  // prettier-ignore
  let price = priceInt / (10 ** storageDecimals);
  //let roundedInitial =  +(Math.round(price + "e+" + decimals.toString())  + "e-" + decimals.toString());
  // prettier-ignore
  let factor = tickSize / (10 ** storageDecimals);
  console.log(`Rounding ${price} to a tickSize of ${factor}`);

  let multiply = 1 / factor;

  return parseFloat(Math.round(price.toString() * multiply) / multiply).toFixed(storageDecimals);

  //return parseFloat((Math.round(price / factor) * factor).toFixed(storageDecimals)); //Math.round(price / factor) * factor;
};

helpers.compareStrings = (a, b) => {
  if (a && b && typeof a === "string" && typeof b === "string") {
    if (a.trim().toLowerCase() > b.trim().toLowerCase()) {
      return 1;
    } else if (a.trim().toLowerCase() < b.trim().toLowerCase()) {
      return -1;
    }
  }
  return 0;
};

helpers.getCurrencyFromId = (currencyId) => {
  const currency = helpers.currencies.find((cur) => cur.id === Number(currencyId)) ?? undefined;
  return currency?.description ?? "";
};

helpers.getCategorySymbol = (category, style) => {
  if (category === "Feedstock") {
    return <OlyxCropfeedstocks style={style} />;
  } else if (category === "Biodiesel") {
    return <OlyxBiofuel style={style} />;
  } else if (category === "Ticket") {
    return <OlyxEnvironmentalTicket style={style} />;
  } else if (category === "Biomethane") {
    return <OlyxBiogas style={style} />;
  } else if (category === "Market Intelligence") {
    return <OlyxMarketintelligence style={style} />;
  } else if (category === "Others") {
    return <OlyxOther style={style} />;
  } else if (category === "Voluntary carbon") {
    return <OlyxEnvironmentalTicket style={style} />;
  } else {
    return <OlyxCollaboration style={style} />;
  }
  return null;
};

helpers.changeValueToDatabaseValue = (value) => {
  return Math.floor(Number(value) * 100);
};

helpers.changeDatabaseValueToDisplayValue = (value) => {
  return Number(value) / 100;
};

helpers.cleanUpUserNumberInput = (value) => {
  const valueWithoutCharactersAndLetters = value.replace(/^(-)|[^0-9.,]+/g, "$1");
  const valueWithDotsInsteadOfCommas = valueWithoutCharactersAndLetters.replace(/,/g, ".");
  if (valueWithDotsInsteadOfCommas === "") {
    return "";
  } else if (isNaN(Number(valueWithDotsInsteadOfCommas))) {
    return false;
  } else {
    return valueWithDotsInsteadOfCommas;
  }
};

helpers.getColorForCategoryIcicleGraph = (id) => {
  if (id === "1") return "#66809C";
  else if (id === "2") return "#FFB310";
  else if (id === "3") return "#588133";
  else if (id === "4") return "#0D6ABF";
  else if (id === "5") return "#22B0FF";
  else if (id === "6") return "#E66C37";
  else if (id === "7") return "#996699";
  else if (id === "8") return "#DAB80E";
  else return "#ccc";
};

helpers.getLayoutForPaymentStatus = (status) => {
  if (status === "DUE") {
    return "h-full w-full bg-[#007bff]";
  } else if (status === "PAID") {
    return "h-full w-full bg-primary-blue";
  } else if (status === "UNKNOWN") {
    return "h-full w-full bg-gray-400";
  } else if (status === "OVERDUE") {
    return "h-full w-full bg-overdue-red";
  } else if (status === "FUTURE") {
    return "h-full w-full bg-white border-gray-200 border";
  } else if (status === "CANCELLED") {
    return "h-full w-full hidden";
  } else if (status === "DELETED") {
    return "h-full w-full bg-grey-400 ";
  } else if (status === "SHOULDBESENTBUTISNOTSENT") {
    return "h-full w-full bg-gray-400";
  } else if (status === "ABOUTTOSEND") {
    return "h-full w-full bg-green-600";
  } else if (status === "DELETED") {
    return "h-full w-full bg-gray-400";
  } else if (status === "CURRENCYERROR") {
    return "h-full w-full bg-yellow-400";
  } else if (status === "ERROR") {
    return "h-full w-full bg-grey-400";
  } else {
    return "";
  }

  return null;
};

export default helpers;
