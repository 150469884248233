import { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import CssTextField from "./CssTextField";

function TextInputCalculated({ label, ...props }) {
  const [field, meta, helper] = useField(props);
  const { values, setFieldValue, touched } = useFormikContext();

  const [manualErrorMessage, setManualErrorMessage] = useState("");

  useEffect(() => {
    if (
      values.commissionSellerUnit !== undefined &&
      values.commissionSellerUnit &&
      values.commissionSellerUnit.trim() !== "" &&
      values.volume !== undefined &&
      values.volume.trim() !== "" &&
      props.name === "commissionSeller"
    ) {
      setFieldValue(props.name, (parseFloat(values.volume) * parseFloat(values.commissionSellerUnit)).toString());
    }
    if (
      values.commissionBuyerUnit !== undefined &&
      values.commissionBuyerUnit.trim() !== "" &&
      values.volume !== undefined &&
      values.volume.trim() !== "" &&
      props.name === "commissionBuyer"
    ) {
      setFieldValue(props.name, (parseFloat(values.volume) * parseFloat(values.commissionBuyerUnit)).toString());
    }
    if (
      values.commissionSellerPercentage !== undefined &&
      values.commissionSellerPercentage &&
      values.commissionSellerPercentage.trim() !== "" &&
      values.volume !== undefined &&
      values.volume.trim() !== "" &&
      values.pricePerUnit !== undefined &&
      values.pricePerUnit.trim() !== "" &&
      props.name === "commissionSeller"
    ) {
      setFieldValue(
        props.name,
        (parseFloat(values.volume) * parseFloat(values.pricePerUnit) * parseFloat(values.commissionSellerPercentage) * parseFloat(0.01)).toString()
      );
    }
    if (
      values.commissionBuyerPercentage !== undefined &&
      values.commissionBuyerPercentage.trim() !== "" &&
      values.volume !== undefined &&
      values.volume.trim() !== "" &&
      values.pricePerUnit !== undefined &&
      values.pricePerUnit.trim() !== "" &&
      props.name === "commissionBuyer"
    ) {
      setFieldValue(
        props.name,
        (parseFloat(values.volume) * parseFloat(values.pricePerUnit) * parseFloat(values.commissionBuyerPercentage) * parseFloat(0.01)).toString()
      );
    }
  }, [
    props.name,
    setFieldValue,
    touched.commissionBuyerUnit,
    touched.commissionBuyerPercentage,
    values.commissionBuyerUnit,
    values.commissionBuyerPercentage,
    touched.commissionSellerUnit,
    touched.commissionSellerPercentage,
    values.commissionSellerUnit,
    values.commissionSellerPercentage,
    touched.volume,
    values.volume,
    touched.pricePerUnit,
    values.pricePerUnit,
  ]);

  useEffect(() => {
    if (
      values.commissionSellerUnit !== undefined &&
      values.commissionSellerUnit !== "" &&
      values.commissionSellerPercentage !== undefined &&
      values.commissionSellerPercentage !== "" &&
      props.name === "commissionSeller"
    ) {
      setManualErrorMessage(
        () =>
          "Note: You both specified the commission per unit AND the commission as a percentage of deal value. Please choose either one of these options and make sure the other field is empty."
      );
    } else {
      setManualErrorMessage(() => "");
    }
  }, [props.name, values.commissionSellerPercentage, values.commissionSellerUnit, touched.commissionSellerPercentage, touched.commissionSellerUnit]);

  useEffect(() => {
    if (
      values.commissionBuyerUnit !== undefined &&
      values.commissionBuyerUnit !== "" &&
      values.commissionBuyerPercentage !== undefined &&
      values.commissionBuyerPercentage !== "" &&
      props.name === "commissionBuyer"
    ) {
      setManualErrorMessage(
        () =>
          "Note: You both specified the commission per unit AND the commission as a percentage of deal value. Please choose either one of these options and make sure the other field is empty."
      );
    } else {
      setManualErrorMessage(() => "");
    }
  }, [props.name, values.commissionBuyerPercentage, values.commissionBuyerUnit, touched.commissionBuyerPercentage, touched.commissionBuyerUnit]);
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <div style={{ padding: 0 }}>
          <label htmlFor={props.id || props.name}>
            {label}
            {props.required && "*"}
          </label>
          <div />
          <div>
            <CssTextField className="text-input" fullWidth inputProps={{ style: { color: "#22b0ff" } }} {...field} {...props} variant="outlined" />
          </div>
          {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
          {manualErrorMessage !== "" && <div className="error"> {manualErrorMessage} </div>}
        </div>
      </Grid>
    </>
  );
}

export default TextInputCalculated;
