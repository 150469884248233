import { motion } from "framer-motion";
import "moment-timezone";
import { useState } from "react";
import ExpRevChanges from "../ExpRevChanges";
import ExpRevChangesForm from "../ExpRevChangesForm/ExpRevChangesForm";
import ExpRevInvoiceTable from "../ExpRevInvoiceTable";

function ExpRevExpandable({ expRev, currentUserIsAdmin, enableChangesOnExpRev }) {
  const [creatingChanges, setCreatingChanges] = useState(false);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { opacity: { delay: 0.2 } } },
    exit: { opacity: 0, transition: { height: { delay: 0.2 } } },
  };

  return (
    <>
      <motion.div class="col-span-7 w-11/12 p-4 mx-auto mb-4 rounded-xl bg-gray-50" initial="hidden" animate="visible" exit="exit" variants={variants}>
        <ExpRevChanges expRev={expRev} currentUserIsAdmin={currentUserIsAdmin} showAllocatedFromParentInvoice={!enableChangesOnExpRev}></ExpRevChanges>
        {enableChangesOnExpRev && (
          <>
            <ExpRevInvoiceTable expRev={expRev} currentUserIsAdmin={currentUserIsAdmin}></ExpRevInvoiceTable>
            {!creatingChanges ? (
              <button className="text-xs text-[#a6a6a6]" onClick={() => setCreatingChanges(!creatingChanges)}>
                + add change
              </button>
            ) : (
              <ExpRevChangesForm expRevId={expRev.id} setCreatingChanges={setCreatingChanges} creatingChanges={creatingChanges}></ExpRevChangesForm>
            )}
          </>
        )}
      </motion.div>
    </>
  );
}

export default ExpRevExpandable;
