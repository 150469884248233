import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { nextSaturday, previousSaturday } from "date-fns";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./PriceChangePopup.css";

const TIME_THRESHOLD = 1000 * 60 * 60 * 12;

export default function PriceChangePopup({
  product,
  disableHoverListen,
  setDisableHoverListen,
  nudgePrice,
  openOnStart = false,
  priceType = "price",
  lastTimeStamp,
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const adjustmentValues = product.adjustmentVals ? product.adjustmentVals.split(",").map(Number) : [0.05, 1, 5];
  const [tmpPrice, setTmpPrice] = useState(
    priceType == "price"
      ? (product.priceInt / 10 ** product.decimals).toFixed(product.decimals) * 1
      : priceType == "bid"
      ? (product.bidInt / 10 ** product.decimals).toFixed(product.decimals) * 1 ?? 0
      : (product.askInt / 10 ** product.decimals).toFixed(product.decimals) * 1 ?? 0
  );
  const [isOpen, setIsOpen] = useState(openOnStart);
  const [extraInfo, setExtraInfo] = useState("");
  const [nLeaveDelay, setNLeaveDela] = useState(50);
  const [selectedSound, setSeletedSound] = useState("sub");
  const controls = useAnimation();

  const lastUpdated = new Date(product.priceUpdateTimestamp);
  const currentDate = new Date();

  // use the current date to create next and past saturday
  const futureSaturday = nextSaturday(new Date(currentDate.getTime()));
  const pastSaturday = previousSaturday(new Date(currentDate.getTime()));

  // check if last updated date is in the current week
  const isCurrentWeek = lastUpdated < futureSaturday && lastUpdated > pastSaturday;
  const pricePercentage = !product.priceweekly ? 0 : parseFloat((((product.priceInt - product.priceweekly) / Math.abs(product.priceweekly)) * 100).toFixed(1));

  useEffect(() => {
    let y;
    if (isCurrentWeek && pricePercentage !== 0) {
      product.priceInt - product.priceweekly > 0 ? (y = [-23, -23, -40, -40, 20, -1, -1, -23]) : (y = [-1, -1, 20, 20, -40, -23, -23, -1]);
      controls.start(() => ({
        y: y,
        opacity: [1, 1, 1, 0, 0, 1, 1, 1],
        transition: { repeat: Infinity, times: [0, 0.4, 0.45, 0.46, 0.46, 0.5, 0.95, 1], repeatType: "loop", duration: 10, ease: "linear" },
      }));
    } else controls.stop();
  }, [product.priceInt]);

  // const is_touch_device = () => {
  //   try {
  //     let prefixes = " -webkit- -moz- -o- -ms- ".split(" ");

  //     let mq = function(query) {
  //       return window.matchMedia(query).matches;
  //     };

  //     if ("ontouchstart" in window || (typeof window.DocumentTouch !== undefined && document instanceof window.DocumentTouch)) {
  //       return true;
  //     }

  //     return mq(["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(""));
  //   } catch (e) {
  //     console.error("(Touch detect failed)", e);
  //     return false;
  //   }
  // };

  const updatePrice = (adj) => {
    if (!disableHoverListen) {
      setDisableHoverListen(true);
      setIsOpen(true);
    }
    setTmpPrice((tmpPrice + adj).toFixed(product.decimals) * 1);
  };

  const opened = () => {
    setIsOpen(true);
  };

  const handleTooltipOpen = () => {
    // setIsOpen(true);
  };

  const closed = () => {
    setIsOpen(false);
    setDisableHoverListen(false);
  };

  const setEditingMode = (isEditingMode) => {
    if (isEditingMode) {
      if (!disableHoverListen) {
        setDisableHoverListen(true);
        setIsOpen(true);
      }
    } else {
      setDisableHoverListen(false);
    }
  };

  const saveThis = () => {
    setDisableHoverListen(false);
    setIsOpen(false);
    let exi = extraInfo;
    let priceInt = (tmpPrice.toFixed(product.decimals) * 1 * 10 ** product.decimals).toFixed(0) * 1;
    nudgePrice(product, priceInt, exi, selectedSound, priceType, lastTimeStamp);
    setExtraInfo("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      saveThis();
    }
  };

  const handleClickAway = () => {
    let tmpPrice =
      priceType == "price"
        ? (product.priceInt / 10 ** product.decimals).toFixed(product.decimals) * 1
        : priceType == "bid"
        ? (product.bidInt / 10 ** product.decimals).toFixed(product.decimals) * 1 ?? 0
        : (product.askInt / 10 ** product.decimals).toFixed(product.decimals) * 1 ?? 0;
    setTmpPrice(tmpPrice);
    setExtraInfo("");
    closed();
  };

  return (
    <div className="flex flex-wrap gap-2 items-center justify-center text-black">
      <Tooltip
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 600 }}
        style={{ maxWidth: 400 }}
        placement="top"
        title={
          <>
            <ClickAwayListener onClickAway={handleClickAway} touchEvent="onTouchStart">
              <div>
                <div style={{ margin: "auto", textAlign: "center", display: "flex" }}>
                  <input
                    onFocus={() => {
                      setEditingMode(true);
                    }}
                    style={{ width: "100%", marginTop: 5, marginBottom: 5, color: "black", paddingLeft: 4 }}
                    placeholder="Additional Info"
                    value={extraInfo}
                    onChange={(evt) => setExtraInfo(evt.target.value)}
                    type="text"
                    onKeyDown={handleKeyDown}
                    maxLength={80}
                  />
                </div>

                <div>
                  {adjustmentValues.reverse().map((val) => {
                    val = -val;
                    return (
                      <Button size="small" variant="outlined" className="nudgeButton" onClick={() => updatePrice(val)}>
                        {val}
                      </Button>
                    );
                  })}

                  <Button size="small" variant="outlined" className="savePriceButton" onClick={saveThis}>
                    {tmpPrice ? tmpPrice : 0}
                  </Button>

                  {adjustmentValues.reverse().map((val) => {
                    return (
                      <Button size="small" variant="outlined" className="nudgeButton" onClick={() => updatePrice(val)}>
                        +{val}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </ClickAwayListener>
          </>
        }
        interactive={true}
        disableTouchListener
        enterTouchDelay={10}
        disableFocusListener
        disableHoverListener
        onOpen={opened}
        onClose={closed}
        open={isOpen}
        leaveTouchDelay={nLeaveDelay}
        leaveDelay={nLeaveDelay}
        PopperProps={{
          popperOptions: {
            modifiers: {
              offset: {
                enabled: true,
                offset: priceType == "price" ? "top,0,-120" : isMobile ? "-90,-120" : "top,0,-100",
              },
            },
          },
        }}
      >
        {priceType === "price" ? (
          <div className="flex gap-2 items-center justify-center">
            <div className="block h-10 mb-4 whitespace-nowrap relative" onClick={handleTooltipOpen}>
              <Typography
                style={{
                  fontSize: priceType == "price" ? 38 : 12,
                  color:
                    priceType == "bid" && isCurrentWeek
                      ? "#ccc"
                      : priceType == "ask" && isCurrentWeek
                      ? "#ccc"
                      : priceType == "bid" || priceType == "ask"
                      ? "#22b0ff"
                      : "#22b0ff",
                  backgroundColor: (priceType == "bid" || priceType == "ask") && isOpen ? "yellow" : "inherit",
                }}
                className="disable-selection dt-price"
                align="center"
              >
                {priceType == "price"
                  ? (product.priceInt / 10 ** product.decimals).toFixed(product.decimals) * 1
                  : priceType == "bid"
                  ? product.bidInt / 10 ** product.decimals ?? 0
                  : product.askInt / 10 ** product.decimals ?? 0}
              </Typography>
            </div>
            <div
              className={`relative block h-5 mb-1.5 whitespace-nowrap overflow-hidden align-center w-20 rounded-xl ${
                pricePercentage === 0 || isCurrentWeek === false ? "bg-gray-200" : pricePercentage > 0 ? "bg-green-200 " : "bg-red-200 "
              }`}
            >
              {pricePercentage === 0 || isCurrentWeek === false ? <p className={"mt-0.5 text-gray-400"}>-%</p> : <></>}
              <motion.div
                animate={controls}
                className={`flex justify-center align-center w-full mx-auto rounded-xl ${isCurrentWeek === false || pricePercentage === 0 ? "hidden" : "flex"}`}
              >
                <p className={` mt-0.5 ${pricePercentage > 0 ? "text-green-700 " : "text-red-700 "}`}>
                  {pricePercentage > 0 ? `+${pricePercentage}% ▲` : `${pricePercentage}% ▼`}
                </p>
              </motion.div>
              <motion.div
                animate={controls}
                className={`flex justify-center align-center w-full mx-auto rounded-xl ${isCurrentWeek === false || pricePercentage === 0 ? "hidden" : "flex"}`}
              >
                <p className={` mt-0.5 ${pricePercentage === 0 ? "text-gray-400 " : pricePercentage > 0 ? "text-green-700 " : "text-red-700 "}`}>
                  {pricePercentage > 0
                    ? `+${(product.priceInt - product.priceweekly) / 10 ** product.decimals} ▲`
                    : `${(product.priceInt - product.priceweekly) / 10 ** product.decimals} ▼`}
                </p>
              </motion.div>
            </div>
          </div>
        ) : (
          <Typography
            style={{
              fontSize: priceType == "price" ? 38 : 12,
              color:
                priceType == "bid" && new Date().getTime() - new Date(product.bidUpdateTimeStamp).getTime() > TIME_THRESHOLD
                  ? "#ccc"
                  : priceType == "ask" && new Date().getTime() - new Date(product.askUpdateTimeStamp).getTime() > TIME_THRESHOLD
                  ? "#ccc"
                  : priceType == "bid" || priceType == "ask"
                  ? "#22b0ff"
                  : "#22b0ff",
              backgroundColor: (priceType == "bid" || priceType == "ask") && isOpen ? "yellow" : "inherit",
            }}
            className="disable-selection dt-price"
            align="center"
            onClick={handleTooltipOpen}
          >
            {priceType == "price"
              ? (product.priceInt / 10 ** product.decimals).toFixed(product.decimals) * 1
              : priceType == "bid"
              ? product.bidInt / 10 ** product.decimals ?? 0
              : product.askInt / 10 ** product.decimals ?? 0}
          </Typography>
        )}
      </Tooltip>
    </div>
  );
}
