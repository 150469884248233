import { DealNote } from "../../codegen/gql/graphql";
import { useActivityHubFilter } from "../../contexts/ActivityHubContexts";
import useUsers from "../../hooks/useUsers";
import { FormField } from "../../interfaces/FormGeneratorProps";
import useDealNotesOverview from "../../typedHooks/queries/queryDealNotesOverview";
import ActivityHubChecklist from "../ActivityHubChecklist/ActivityHubChecklist";
import ActivityHubComments from "../ActivityHubComments/ActivityHubComments";
import FormGenerator from "../FormGenerator/FormGenerator";

const ActivityHub = () => {
  const { setUserView, userView, selectedUserViewInArray, queryDealnotesOverviewInput } = useActivityHubFilter();
  const { users } = useUsers() as any;
  const { dataFromQuery: dealNotesOverviewData } = useDealNotesOverview(queryDealnotesOverviewInput);
  const dealNotesToDo = dealNotesOverviewData?.dealnotesoverview?.dealNotesTodo as DealNote[] | undefined;
  const dealNotesInProgress = dealNotesOverviewData?.dealnotesoverview?.dealNotesInProgress as DealNote[] | undefined;
  const dealNotesDone = dealNotesOverviewData?.dealnotesoverview?.dealNotesDone as DealNote[] | undefined;

  const userDropdown: { [key: string]: FormField } = {
    userFields: {
      type: "dropdown",
      initialValue: String(userView),
      label: "User view",
      order: "order-1",
      onChange: (userId: number) => {
        setUserView(userId ? Number(userId) : 0);
      },
      options: users ? users.users.map((user: any) => ({ id: String(user.id), name: user.fullName })) : [],
    },
  };

  return (
    <div className="p-4 xl:p-0">
      {users && "users" in users && users.users.length > 0 && (
        <FormGenerator
          onSubmitFromParent={(e) => {
            e.preventDefault();
          }}
          layoutOptions={{
            hasLabelsOnTop: true,
            hasCloseButton: false,
          }}
          customFormStyles={{
            parent: "h-full  w-full rounded-xl p-[16px] pt-[24px] border-box flex flex-wrap gap-x-[1%] bg-white justify-start",
            children: "w-full py-[10px] pt-[24px] md:p-[0px] md:w-[33%] md:pt-[0px] xl:w-[25%]",
          }}
          formFields={[...Object.values(userDropdown)]}
        ></FormGenerator>
      )}
      <div className="flex mt-8 w-full justify-between flex-wrap gap-y-4">
        <ActivityHubChecklist />
        <ActivityHubComments dealNotesToDo={dealNotesToDo} dealNotesInProgress={dealNotesInProgress} dealNotesDone={dealNotesDone} />
      </div>
    </div>
  );
};
export default ActivityHub;
