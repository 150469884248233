import { withRouter } from "react-router-dom";
import ActivityHub from "../../components/ActivityHub/ActivityHub";
import { ActivityHubFilterProvider } from "../../contexts/ActivityHubContexts";

const HomePage = withRouter((props) => {
  return (
    <div className="root">
      <ActivityHubFilterProvider>
        <ActivityHub />
      </ActivityHubFilterProvider>
    </div>
  );
});

export default HomePage;
