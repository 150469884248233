import { AnimatePresence, motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import { DealConfirmation } from "../../../codegen/gql/graphql";
import { useActivityHubFilter } from "../../../contexts/ActivityHubContexts";
import useDealConfirmations from "../../../typedHooks/queries/queryDealConfirmations";
import DealConfirmationContainer from "../../DealConfirmationContainer/DealConfirmationContainer";

const ChecklistItems = ({ column }: { column: string }) => {
  const [confirmationsToShow, setConfirmationsToShow] = useState(20);
  const { selectedUserViewInArray, checklistView } = useActivityHubFilter();
  const { dataFromQuery: dealConfirmationsFromQuery, refetch } = useDealConfirmations({
    userIds: selectedUserViewInArray,
    isConfirmed: column === "Done",
    take: confirmationsToShow,
  });

  useEffect(() => {
    refetch();
  }, [checklistView]);

  const dealConfirmations = dealConfirmationsFromQuery?.dealconfirmations as DealConfirmation[] | undefined;

  const container: Variants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  const item: Variants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="mt-3 flex flex-wrap gap-y-[16px]"
      variants={container}
      initial="hidden"
      animate={dealConfirmations && dealConfirmations.length > 0 ? "visible" : "hidden"}
      exit="hidden"
    >
      <AnimatePresence>
        {dealConfirmations &&
          dealConfirmations.map((confirmation) => {
            const isDealConfirmation = confirmation.__typename === "DealConfirmation";
            return (
              <motion.div className="w-full" variants={item} key={isDealConfirmation ? confirmation.id : ""}>
                <DealConfirmationContainer
                  key={isDealConfirmation ? confirmation.id : ""}
                  clientName={
                    isDealConfirmation
                      ? confirmation.tradeSide === "BUY"
                        ? confirmation.deal.buyer!.companyName
                        : confirmation.deal.seller!.companyName
                      : "Unknown"
                  }
                  confirmation={confirmation}
                  olyxRef={isDealConfirmation ? String(confirmation.deal.olyxRef) : ""}
                />
              </motion.div>
            );
          })}
        {dealConfirmations && confirmationsToShow === dealConfirmations.length && (
          <button
            onClick={() => {
              setConfirmationsToShow(confirmationsToShow + 20);
            }}
            className="w-fit rounded-xl mt-2 p-2 py-1 text-sm block ml-auto text-primary-blue hover:text-[#24abf6] cursor-pointer"
          >
            Show more...
          </button>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ChecklistItems;
