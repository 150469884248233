import AddDealSelect from "./../../components/AddFormDeal/AddDealSelect";
import { withRouter } from "react-router-dom";

function AddDealExtensivePage() {
  return (
    <>
      <AddDealSelect extensive={true} />
    </>
  );
}

export default withRouter(AddDealExtensivePage);
