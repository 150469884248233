import { gql, useMutation } from "@apollo/client";

const MARK_DEALCONFIRMATOIN_AS_CONFIRMED = gql`
  mutation MarkDealConfirmationAsConfirmed($input: MarkDealConfirmationAsConfirmedInput) {
    markdealconfirmationasconfirmed(input: $input) {
      ... on DealConfirmation {
        id
        isConfirmed
        updatedAt
        updatedBy {
          fullName
        }
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`;

const useMarkDealConfirmationAsConfirmed = () => {
  const [markDealConfirmationAsConfirmed] = useMutation(MARK_DEALCONFIRMATOIN_AS_CONFIRMED, {
    refetchQueries: ["dealconfirmationscount"],
  });

  return markDealConfirmationAsConfirmed;
};

export default useMarkDealConfirmationAsConfirmed;
