import { graphql } from "../../codegen/gql";
import { OpenDealNoteCountQuery, OpenDealNoteCountQueryVariables } from "../../codegen/gql/graphql";
import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";

export const GET_OPENDEALNOTECOUNT = graphql(`
  query OpenDealNoteCount {
    opendealnotecount
  }
`);

const useOpenDealNoteCount = (input: OpenDealNoteCountQueryVariables) =>
  useGeneratedTypeQuery<OpenDealNoteCountQuery, OpenDealNoteCountQueryVariables>(GET_OPENDEALNOTECOUNT, input);

export default useOpenDealNoteCount;
