import { Formik, Form } from "formik";
import AutoCompleteInput from "../FormFields/AutoCompleteInput";
import { gql, useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";

const QUERY_DEAL = gql`
  query Deal($dealStatusId: ID) {
    deals(dealStatusId: $dealStatusId) {
      id
      olyxRef
      description
    }
  }
`;

const getInitialValues = (formFields) =>
  formFields.reduce((acc, formField) => {
    const fieldValuePair = {};
    fieldValuePair[formField.name] = formField["defaultValue"];
    return { ...acc, ...fieldValuePair };
  }, {});

function SelectDeal(props) {
  const { data } = useQuery(QUERY_DEAL, {
    variables: { dealStatusId: "4" },
    pollInterval: 1500,
  });

  const refetchSingle = props.singleDeal.refetch;

  // const formField ={name: "olyxRef", label: "Olyx Ref Number", rank: 1, fieldType:"text", yupType: "integer", required: true, defaultValue: "0",};
  const formFields = [
    {
      name: "olyxRef",
      label: "Olyx Ref Number",
      rank: 1,
      fieldType: "select",
      yupType: "integer",
      required: true,
      defaultValue: "",
      optionObjects: "deals",
      optionFieldsToDisplay: ["id", "olyxRef", "description"],
    },
  ];
  const formField = formFields[0];

  const initialValues = props.initialValues ? props.initialValues : getInitialValues(formFields);

  return (
    <div style={styles.root}>
      <h3>Select a deal you'd like to edit</h3>
      {data && (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            refetchSingle({ id: values.olyxRef });
          }}
        >
          <Form>
            {data && (
              <AutoCompleteInput
                id={formField.name}
                label={formField.label}
                name={formField.name}
                optionObjects={[...data[formField.optionObjects]]}
                optionFieldsToDisplay={[...formField.optionFieldsToDisplay]}
                required={formField.required}
                sortByIdDesc={true}
                style={{ width: 300 }}
              />
            )}
            <div style={styles.contentWrapper}>
              {data && (
                <Button type="submit" color="secondary" variant="outlined">
                  Complete this deal
                </Button>
              )}
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
}

SelectDeal.defaultProps = {};

SelectDeal.propTypes = {};

const styles = {
  root: {
    backgroundColor: "#1f3a56",
    marginLeft: -10,
    padding: 10,
  },
  contentWrapper: {
    paddingTop: 30,
    paddingBottom: 30,
  },
};

export default SelectDeal;
