import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const UPDATE_EXPECTEDREVENUE = gql`
  mutation ExpectedRevenue($input: ExpectedRevenueInput!) {
    createexpectedrevenue(input: $input) {
      ... on ExpectedRevenue {
        id
        deal {
          id
        }
        client {
          id
        }
        totalAmount
        volume
        currency {
          id
        }
        dateExpected
      }
      ... on ExpectedRevenueError {
        errorCode
        message
      }
    }
  }
`;

const useUpdateExpectedRevenue = () => {
  const [updateExpectedRevenue] = useMutation(UPDATE_EXPECTEDREVENUE, { refetchQueries: ["Deal"] });

  return (input: any) => updateExpectedRevenue(input);
};

export default useUpdateExpectedRevenue;
