import React from "react";
import MessageIcon from "@material-ui/icons/Mail";
import { Link } from "react-router-dom";
import useDealConfirmationsCount from "../../typedHooks/queries/queryDealConfirmationsCount";
import useOpenDealNoteCount from "../../typedHooks/queries/queryOpenDealNoteCount";

export default function MentionNotificationIconButton() {
  let localUser = JSON.parse(localStorage.getItem("user")!)?.id ?? 0;
  const { dataFromQuery: openDealNoteCount } = useOpenDealNoteCount({});
  const { dataFromQuery: dealConfirmationsCount } = useDealConfirmationsCount({ userIds: [localUser], isConfirmed: false });

  const openDealNoteCountInt: number = openDealNoteCount?.opendealnotecount ?? 0;
  const dealConfirmationsCountInt: number = dealConfirmationsCount?.dealconfirmationscount ?? 0;
  const totalNotificationCount = openDealNoteCountInt + dealConfirmationsCountInt;

  return (
    <div className="p-3 pr-1 rounded-full hover:bg-white hover:bg-opacity-10">
      <Link className="relative mr-2" to={"/"}>
        <MessageIcon />
        {totalNotificationCount > 0 && (
          <div
            data-testid="noti-badge"
            className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 rounded-full -top-[1.06rem] -right-3 border-gray-900"
          >
            {totalNotificationCount > 99 ? "99+" : totalNotificationCount}
          </div>
        )}
      </Link>
    </div>
  );
}
