import ActivityHub from "../../components/ActivityHub/ActivityHub";
import MissingDealNumbers from "../../components/MissingDealNumbers/";
import "./DealManagementPage.scss";

function DealManagementPage() {
  return (
    <div className="root">
      <ActivityHub />
      <MissingDealNumbers />
    </div>
  );
}

export default DealManagementPage;
