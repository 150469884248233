import { motion } from "framer-motion";
import Dupont from "../../assets/dupont.png";
import ManPoint from "../../assets/man-point.png";
import { default as ExternalLinkPageSectionGeneratorProps, default as ExternalLinkPageSectionProps } from "../../interfaces/ExternalLinkPageProps";

const ExternalLinkSectionGenerator = ({ allSections }: ExternalLinkPageSectionGeneratorProps) => {
  return allSections.map((value: ExternalLinkPageSectionProps, idx) => {
    const container = {
      hidden: { opacity: 1, scale: 0 },
      visible: {
        opacity: 1,
        scale: 1,
        transition: {
          delayChildren: 0.1 * value.order,
          staggerChildren: 0.4,
        },
      },
    };

    const item = {
      hidden: { y: 20, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1,
      },
    };

    const charles = {
      hidden: { rotate: 0 },
      visible: {
        rotate: [0, 30, 0, -30, 0],
        transition: {
          duration: 4,
          repeat: Infinity,
          stiffness: 260,
        },
      },
    };

    return (
      <div key={idx} className=" w-full md:w-1/2 xl:w-1/3 snap-center flex items-center justify-center">
        <motion.div variants={container} className="flex-wrap container flex justify-center items-center" initial="hidden" animate="visible">
          <div className="relative mx-auto w-[180px] xl:w-1/2 justify-end flex">
            <motion.img variants={item} className="item w-[200px] h-[fit]" src={value.image} alt="Academy-Book-Stack" />
            {value.order === 5 && (
              <>
                <img className="item w-[140px] h-[fit] absolute top-[-3px] right-[30px] xl:right-[45px] origin-bottom" src={ManPoint} alt="Charles-Dupont" />
                <motion.img
                  variants={charles}
                  className="item w-[54px] h-[fit] absolute -top-[48px] right-[84px] xl:right-[95px] origin-bottom"
                  src={Dupont}
                  alt="Charles-Dupont"
                />
              </>
            )}
          </div>
          <motion.div
            className=" p-[10px] xl:p-[16px] border-white min-h-[180px] items-end justify-center xl:justify-start flex-wrap flex grow w-full xl:w-1/2"
            variants={item}
          >
            {value.description && (
              <p className="w-[full] min-h-[80px] item pb-[16px] px-[64px] xl:px-0 text-[13px] text-white border-[#D9DFE3]">{value.description}</p>
            )}
            <div className="w-fit relative flex justify-start items-center">
              <a
                className="transition-all py-[12px] text-white text-[14px] px-[16px] rounded-[10px] border-[2px] border-white"
                target="_blank"
                href={value.buttonLink}
                rel="noreferrer"
              >
                {value.buttonText}
              </a>
              {value.buttonExtraInfo && (
                <p className="bg-[#444444] text-xs text-center text-white w-[120px] h-[24px] absolute bottom-[-24px] translate-x-2/4 right-[50%] rounded-bl-[10px] rounded-br-[10px] px-[8px] py-[4px] pt-[4px]">
                  {value.buttonExtraInfo}
                </p>
              )}
            </div>
          </motion.div>
        </motion.div>
      </div>
    );
  });
};
export default ExternalLinkSectionGenerator;
