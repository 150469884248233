import { graphql } from "../../codegen/gql";
import { DealconfirmationsQuery, QueryDealConfirmationInput } from "../../codegen/gql/graphql";
import useGeneratedTypeQueryWithPagination from "../helpers/useGeneratedTypeQueryWithPagination";

const GET_DEALCONFIRMATIONS = graphql(`
  query dealconfirmations($input: QueryDealConfirmationInput!) {
    dealconfirmations(input: $input) {
      ... on DealConfirmation {
        id
        createdAt
        isConfirmed
        deal {
          id
          olyxRef
          seller {
            id
            companyName
          }
          buyer {
            id
            companyName
          }
        }
        tradeSide
        updatedAt
        updatedBy {
          id
          imageUrl
          fullName
        }
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`);

const useDealConfirmations = (input: QueryDealConfirmationInput) =>
  useGeneratedTypeQueryWithPagination<DealconfirmationsQuery, QueryDealConfirmationInput>(GET_DEALCONFIRMATIONS, input);

export default useDealConfirmations;
