import { motion } from "framer-motion";

const EmptyState = ({ title, description }: { title: string; description?: string }) => {
  return (
    <motion.div className="mt-3" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
      <div className="bg-gray-100 px-4 py-8 rounded-xl text-center w-full mt-4">
        <span className="text-lg text-center text-gray-400">{title}</span>
        <span> {description}</span>
      </div>
    </motion.div>
  );
};
export default EmptyState;
