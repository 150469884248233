import queryString from "query-string";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { QueryDealNotesOverviewInput } from "../codegen/gql/graphql";
import parsedStringToBoolean from "../services/helperFunctions/parsedStringToBoolean";
import parsedStringToNumber from "../services/helperFunctions/parsedStringToNumber";
import useDealNotesOverview from "../typedHooks/queries/queryDealNotesOverview";

interface FeedFilterContext {
  userView: number;
  mentioned: boolean;
  sent: boolean;
  others: boolean;
  commentsView: string;
  checklistView: string;
  selectedUserViewInArray: number[];
  setUserView: (userView: number) => void;
  setMentioned: (mentioned: boolean) => void;
  setSent: (sent: boolean) => void;
  setOthers: (others: boolean) => void;
  setCommentsView: (commentsView: string) => void;
  setChecklistView: (checklistView: string) => void;
  queryDealnotesOverviewInput: QueryDealNotesOverviewInput;
}

const ActivityHubFilterContext = createContext<FeedFilterContext | undefined>(undefined);

export const useActivityHubFilter = () => {
  const context = useContext(ActivityHubFilterContext);
  if (!context) {
    throw new Error("useActivityHubFilter must be used within a ActivityHubFilterProvider");
  }
  return context;
};

export const ActivityHubFilterProvider = ({ children }: { children: React.ReactNode }) => {
  let localUser = JSON.parse(localStorage.getItem("user")!).id ?? 0;

  // cant type user since users is still plain js
  const history = useHistory();
  const queryParams = queryString.parse(window.location.search) as {
    mentioned: string;
    others: string;
    sent: string;
    userView: string;
  };

  const [userView, setUserView] = useState<number>(parsedStringToNumber(queryParams.userView, localUser));
  const [mentioned, setMentioned] = useState<boolean>(parsedStringToBoolean(queryParams.mentioned, true));
  const [sent, setSent] = useState<boolean>(parsedStringToBoolean(queryParams.sent, false));
  const [others, setOthers] = useState<boolean>(parsedStringToBoolean(queryParams.others, false));
  const [commentsView, setCommentsView] = useState<string>("InProgress");
  const [checklistView, setChecklistView] = useState<string>("toDo");

  const selectedUserViewInArray = userView === 0 ? [] : [userView];

  const newQueries: { userView: number; mentioned: boolean; sent: boolean; others: boolean } = {
    userView: userView,
    mentioned: mentioned,
    sent: sent,
    others: others,
  };

  const queryDealnotesOverviewInput: QueryDealNotesOverviewInput = {
    userIds: [userView],
    isMentioned: mentioned,
    hasSent: sent,
    includeNoMention: others,
  };

  const { refetch } = useDealNotesOverview(queryDealnotesOverviewInput);

  useEffect(() => {
    history.replace({ search: queryString.stringify(newQueries) });
    refetch();
  }, [userView, mentioned, sent, others]);

  return (
    <ActivityHubFilterContext.Provider
      value={{
        userView,
        mentioned,
        sent,
        others,
        commentsView,
        checklistView,
        selectedUserViewInArray,
        setUserView,
        setMentioned,
        setSent,
        setOthers,
        setCommentsView,
        setChecklistView,
        queryDealnotesOverviewInput,
      }}
    >
      {children}
    </ActivityHubFilterContext.Provider>
  );
};
