import { useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import Avatar from "../Avatar/Avatar";

function TextAreaWithMention({ userList, addNote, formError, setFormError, loading }) {
  const [textContent, setTextContent] = useState("");
  const [mentionedUsersList, setMentionedUsersList] = useState([]);
  const [usersListFormatted, setUsersListFormatted] = useState([]);

  useEffect(() => {
    if (userList.users && userList.users.length > 0) {
      let list = userList.users.map(({ id, fullName, imageUrl }) => ({ id: id, display: fullName, avatar: `${imageUrl}` }));
      setUsersListFormatted(list);
    }
  }, [userList.users]);

  const renderSuggestionCustom = (suggestion, search, highlightedDisplay, index, focused) => {
    return (
      <div className={`user ${focused ? "focused bg-[#D8EDF4]" : "bg-white"} rounded-lg text-left p-2 text-[#7E8085]`}>
        <Avatar src={suggestion.avatar} className={"w-7 avatar inline mr-2"} />
        {highlightedDisplay}
      </div>
    );
  };

  const renderSuggestionContainerCustom = (children) => {
    return <div className={"rounded-lg p-2 drop-shadow-lg bg-white z-[100] relative"}>{children}</div>;
  };

  const addNotePressed = (addNote) => {
    addNote(textContent, mentionedUsersList)
      .then(() => {
        setTextContent("");
        setMentionedUsersList([]);
        setFormError(null);
      })
      .catch((error) => {
        setFormError("Error saving deal comment!"); //TODO: log4js
      });
  };

  return (
    userList.users && (
      <div className="textAreaMentions h-auto w-full rounded-xl font-title text-center mt-2 border border-light-gray border border-solid p-2 bg-white flex flex-col mx-auto">
        <MentionsInput
          value={textContent}
          onChange={(e, newValue, newPlainTextValue, mentions) => {
            setTextContent(newValue);
            setMentionedUsersList(mentions.reduce((acc, mention) => [...acc, { mentionedUserId: mention.id }], []));
          }}
          placeholder="Write your note here, use @ to tag someone..."
          singleLine={false}
          className="w-full bg-white flex-grow text-sm"
          customSuggestionsContainer={renderSuggestionContainerCustom}
          allowSpaceInQuery
        >
          <Mention
            trigger="@"
            data={usersListFormatted}
            className={"bg-white py-0.5 text-justify text-primary-blue z-50 relative "}
            renderSuggestion={renderSuggestionCustom}
            displayTransform={(id, display) => ` @${display} `}
            appendSpaceOnAdd={true}
          />
        </MentionsInput>
        <div className={"flex h-12"}>
          <div></div>
          {/* Attachment Feature for Later */}
          {/* <div>
          <button className={"p-3 text-[#7E8085] text-xs"} onClick={() => attachFile(mentionedUsersList)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
              />
            </svg>
          </button>
        </div> */}
          <div className={"w-32 mt-5 ml-auto"}>
            <button disabled={loading} class="w-26 block px-2 py-1 ml-auto text-white bg-primary-blue rounded-xl" onClick={() => addNotePressed(addNote)}>
              Add note
            </button>
          </div>
        </div>
        {formError && <div className="error text-red-800 p-2">{formError}</div>}
      </div>
    )
  );
}

export default TextAreaWithMention;
