import React, { useMemo } from "react";
import "./AutoCompleteInput.css";
import { useField, useFormikContext } from "formik";
import AutoComplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CssTextField from "./CssTextField";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

function AutoCompleteInput({ label, optionObjects, optionFieldsToDisplay, sortByIdDesc, sortingAlgorithm, conditionOn, ...props }) {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  const { values } = useFormikContext();

  // create const options
  const options = useMemo(
    () =>
      [...optionObjects]
        .sort((first, second) => (sortingAlgorithm ? sortingAlgorithm(first, second) : sortByIdDesc ? second.id - first.id : first.id - second.id))
        .filter((item) => {
          if (conditionOn && item.id && values[conditionOn + "Id"]) {
            return item[conditionOn].id.toString() === values[conditionOn + "Id"];
          }
          return item;
        })
        .filter((item) => (Object.keys(item).includes("isActive") && item.isActive === true) || !Object.keys(item).includes("isActive"))
        .map((item) => item.id.toString()),
    [values, conditionOn, optionObjects, sortByIdDesc, sortingAlgorithm]
  );

  const getCustomOptionLabel = (option) => {
    const optionObj = optionObjects.filter((item) => item.id.toString() === option.toString());
    return optionFieldsToDisplay
      .filter((field) => field !== "id")
      .reduce(
        (acc, field) =>
          optionObj[0] !== undefined
            ? optionObj[0][field] === undefined || optionObj[0][field] === null || optionObj[field] === 0
              ? acc //'Choose an optionObj'
              : acc + optionObj[0][field].toString() + " "
            : "", //'Choose an option',//undef op[field]',
        ""
      );
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Typography variant="body2">
          {label}
          {props.required && "*"}
        </Typography>
        <AutoComplete
          id={props.name}
          value={value === undefined ? "" : value.toString()}
          fullWidth={true}
          options={["", ...options]}
          getOptionLabel={(option) => getCustomOptionLabel(option)}
          onChange={(event, value) => setValue(value !== null ? value : "")}
          {...props}
          renderInput={(params) => (
            <CssTextField
              {...params}
              label={props.label}
              inputProps={{ ...params.inputProps, style: { color: "#22b0ff" } }}
              variant="outlined"
              color="primary"
            />
          )}
          renderOption={(option) => {
            if (props.name === "importCountryId" || props.name === "exportCountryId") {
              const optionObj = optionObjects.filter((item) => item.id.toString() === option.toString());
              if (optionObj.length !== 0) {
                return (
                  <React.Fragment>
                    <span>{countryToFlag(optionObjects.filter((item) => item.id.toString() === option.toString())[0]["code"].toString())}</span>
                    {optionObj[0]["name"].toString()} ({optionObj[0]["code"].toString()})
                  </React.Fragment>
                );
              } else {
                return getCustomOptionLabel(option);
              }
            } else {
              return getCustomOptionLabel(option);
            }
          }}
          includeInputInList={true}
          autoSelect={true}
          autoComplete={true}
        />
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </Grid>
    </>
  );
}

export default AutoCompleteInput;
