import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import helpers from "../../../services/helpers";
import { NumberInputProps } from "../../../interfaces/InputProps";

const NumberInput = (props: NumberInputProps) => {
  const [inputHasBeenActive, setInputHasBeenActive] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<string>(props.initialValue ? props.initialValue : "");
  const numberToDisplay = props.initialValue ? helpers.formatMoney(props.initialValue) : "";
  let ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      checkInputValidity(ref.current);
    }
  }, [props.initialValue]);

  useEffect(() => {
    if (ref.current && props.order === "order-first") {
      ref.current.focus();
    }
  }, [props.order]);

  const makeValueComplyWithStep = (value: string) => {
    const initialValueDecimals = value.split(".")[1]?.length;
    const stepDecimals = props.step.split(".")[1]?.length;
    if (initialValueDecimals === undefined || initialValueDecimals < stepDecimals) {
      return;
    } else {
      if (initialValueDecimals > stepDecimals) {
        const initialValueTrimDecimalsBy = (initialValueDecimals - stepDecimals) * -1;
        setInitialValue(value.slice(0, initialValueTrimDecimalsBy));
      }
    }
  };

  useEffect(() => {
    makeValueComplyWithStep(initialValue);
  }, []);

  const checkInputValidity = (input: EventTarget & HTMLTextAreaElement) => {
    const submittedValue = input.value.replace(/,/g, ".");
    const initialValueDecimals = submittedValue.split(".")[1]?.length;
    const stepDecimals = props.step.split(".")[1]?.length;

    if (props.step.length !== 0) {
      if (initialValueDecimals > stepDecimals && props.step === "0.01") {
        input.setCustomValidity("Needs to be 2 decimals.");
      } else if (initialValueDecimals >= 1 && props.step === "1") {
        input.setCustomValidity("Number cannot contain decimals.");
      } else if (initialValueDecimals === stepDecimals || initialValueDecimals <= stepDecimals || props.step === "any" || initialValueDecimals === undefined) {
        input.setCustomValidity("");
      }
    }

    return input.checkValidity();
  };

  useEffect(() => {
    const makeInitialValueComplyWithStep = () => {
      const initialValueDecimals = initialValue.split(".")[1]?.length;
      const stepDecimals = props.step.split(".")[1]?.length;
      if (initialValueDecimals === undefined || initialValueDecimals < stepDecimals) {
        return;
      } else {
        if (initialValueDecimals > stepDecimals) {
          const initialValueTrimDecimalsBy = (initialValueDecimals - stepDecimals) * -1;
          setInitialValue(initialValue.slice(0, initialValueTrimDecimalsBy));
        }
      }
    };
    makeInitialValueComplyWithStep();
  }, []);

  return (
    <div className={`relative ${props.customFormStyles ? props.customFormStyles : "h-12 max-w-fit"} ${props.order ? props.order : ""}`} id="rootDiv">
      <div className="p-[4px] h-[2.7em] flex flex-nowrap rounded-xl border border-solid border-gray-200 text-base items-center">
        <div className="flex flex-row flex-nowrap items-center relative w-full h-full">
          <label
            className={`${
              props.hasLabelsOnTop ? "absolute " : "inherit "
            }bg-white left-1 bottom-[30px] whitespace-nowrap md:m-auto px-1 text-[#9aa6b2] max-md:text-sm md:text-base`}
            htmlFor={props.label}
          >
            {props.label}:
          </label>
          {numberToDisplay && (
            <span
              className={`absolute text-xs text-[#787878] text-left min-w-[100px] min-h-[18px] rounded-tl-[6px] rounded-tr-[6px] py-[1px] px-[12px] bg-[#f9f9f9] border-[1px] border-b-0 border-[#efefef] bottom-[-4px] right-[32px] z-10 text-dark-gray-filters pointer-events-none
          ${props.showDefaultValue ? (inputHasBeenActive ? "text-opacity-100" : "text-opacity-50") : "text-opacity-100"}`}
            >
              {numberToDisplay ? `( ${numberToDisplay} )` : ""}
            </span>
          )}

          <div className="w-full h-full text-dark-gray-filters grow relative flex items-center flex-row flex-nowrap bg-transparent text-opacity-50">
            <textarea
              className={`w-full text-dark-gray-filters focus:outline-0 border-[1px] border-transparent 
              focus:border-[1px] ${props.showDefaultValue ? (inputHasBeenActive ? "text-opacity-100" : "text-opacity-50") : "text-opacity-100"}
                invalid:border-red-200 invalid:bg-red-100  h-[100%] pl-2 pt-[4px] pr-2 rounded-[6px]`}
              value={props.initialValue}
              id={props.label}
              ref={ref}
              required={props.isRequired ?? true}
              onWheel={(e) => e.currentTarget.blur()}
              onChange={
                props.onChange
                  ? (e) => {
                      const valueReadyToSubMit = helpers.cleanUpUserNumberInput(e.target.value);
                      if (valueReadyToSubMit !== false) {
                        checkInputValidity(e.target);
                        props.onChange(valueReadyToSubMit);
                      }
                    }
                  : undefined
              }
              onFocus={() => {
                setInputHasBeenActive(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
