import { graphql } from "../../codegen/gql";
import { DealconfirmationscountQuery, QueryDealConfirmationInput } from "../../codegen/gql/graphql";
import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";

export const GET_DEALCONFIRMATIONSCOUNT = graphql(`
  query dealconfirmationscount($input: QueryDealConfirmationInput!) {
    dealconfirmationscount(input: $input)
  }
`);

const useDealConfirmationsCount = (input: QueryDealConfirmationInput) =>
  useGeneratedTypeQuery<DealconfirmationscountQuery, QueryDealConfirmationInput>(GET_DEALCONFIRMATIONSCOUNT, input);

export default useDealConfirmationsCount;
